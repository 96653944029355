import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Cooking App Template | Cooking App Design | Uizard"
    canonical="/templates/mobile-app-templates/cooking-mobile-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/cooking-tablet-app/"
    metaDescription="Introducing CookUp!, our tablet cooking app design template. Create your own tablet cooking app design with ease. Try now."
    description="
    h2:Introducing CookUp! Our cooking app design template for tablet
    <br/>
    CookUp!, our tablet app design template, comes with everything you could possibly need to make a stunning tablet app design. Our cooking template is sure to get the juices flowing, with its refined style and natural UI flow. Work collaboratively and cook up your own stunning design in minutes.
    <br/>
    h3:CookUp! If five-star food was a design template…
    <br/>
    CookUp! is a <a:https://uizard.io/templates/>UI design template</a> that comes with all the must-have screens and components that a Michelin star cooking app could possibly need to help you go from idea to final interaction. Uizard is all about speed and helps you to find the best ingredients to give your own designs as much flavor as you can pack in, as quickly as possible.
    <br/>
    h3:A pinch of this, a sprinkle of that…
    <br/>
    Uizard is all about rapid customization, but that doesn't mean you can't add your own style to things. Sure, CookUp! Is ready to go straight out of the box but all our prototypes come packed with a multitude of different components and stylizations, meaning you can really make your cooking tablet app design your own.
    "
    pages={[
      "A stunning app landing page design",
      "A clean and clear login screen to streamline user experience, with a simple homepage interface",
      "A clean nav footer bar linking all of the core pages of you design together",
      "Stunning recipe pages, a chic profile section, and a search bar for ease of navigation",
    ]}
    projectCode="d9RWbmRwMLCZdp7lPvby"
    img1={data.image1.childImageSharp}
    img1alt="cooking tablet app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="cooking tablet app design template recipe screen"
    img3={data.image3.childImageSharp}
    img3alt="cooking tablet app design template recipe search screen"
    img4={data.image4.childImageSharp}
    img4alt="cooking tablet app design template list screen"
    img5={data.image5.childImageSharp}
    img5alt="cooking tablet app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/cooking-tablet-app/cooking-tab-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/cooking-tablet-app/cooking-tab-app-recipe.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/cooking-tablet-app/cooking-tab-app-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/cooking-tablet-app/cooking-tab-app-list.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/cooking-tablet-app/cooking-tab-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
